import React from "react";
import { Typography } from "@ryerson/frontend.typography";
import { css } from "@emotion/react";
import { Container, Flex, FlexItem, Media } from "@ryerson/frontend.layout";
import { Link } from "@ryerson/frontend.navigation";
import { useTheme } from "@ryerson/frontend.theme";
import { Icon } from "@ryerson/frontend.assets";
import styled from "@emotion/styled";
import CookieBanner from "@components/CookieBanner";
import { Button } from "@ryerson/frontend.button";
const Menu = styled.div`
	position: fixed;
	top: 80px;
	left: 100%;
	width: 100%;
	z-index: 10000;
	padding: 20px;
	height: 160px;
	box-sizing: border-box;
	visibility: hidden;
	transition: left 0.25s linear, visibility 0.25s linear;
	box-shadow: -2px 1px 8px 1px rgba(0, 0, 0, 0.2);
`;

export type HeaderContentType = {
	label: string;
	url: string;
};

export const HeaderContent: HeaderContentType[] = [
	{
		label: "About",
		url: "/about",
	},
	{
		label: "Insights",
		url: "/insights",
	},
	{
		label: "Expertise",
		url: "/expertise",
	},
];

const NextHeader = () => {
	const { theme } = useTheme();
	const [menuShowing, setMenuShowing] = React.useState<boolean>(false);

	return (
		<>
			<Container>
				<Menu
					css={css`
						${menuShowing
							? "left:0%;visibility:visible;"
							: "left:100%;visibility:hidden;"};
						background-color: ${theme.colors.primary.lighterGray};
						@media (min-width: ${theme.breakpoints.lg}) {
							visibility: hidden !important;
						}
					`}
				>
					<Flex direction="column" alignItems="center">
						{HeaderContent.map((item: HeaderContentType, index: number) => {
							return (
								<FlexItem key={index}>
									<Link to={item.url}>
										<Typography
											variant="div"
											color={theme.colors.primary.primaryBrand}
											css={css`
												text-transform: uppercase;
												height: 40px;
												display: block;
												line-height: 40px !important;
												text-align: center;
											`}
										>
											{item.label}
										</Typography>
									</Link>
								</FlexItem>
							);
						})}
					</Flex>
				</Menu>
				<Flex
					direction="row"
					justifyContent="space-between"
					alignItems="center"
					css={css`
						@media (max-width: ${theme.breakpoints.lg}) {
							justify-content: flex-start;
							gap: 30px;
						}
					`}
				>
					<FlexItem
						css={css`
							@media (max-width: ${theme.breakpoints.sm}) {
								display: none;
							}
						`}
					>
						<Link to="/">
							<img
								src={"/images/the-next-shift.png"}
								css={css`
									display: block;
									width: 240px;
									height: auto;
									max-width: 100%;
								`}
							/>
						</Link>
					</FlexItem>
					<FlexItem
						css={css`
							@media (max-width: ${theme.breakpoints.lg}) {
								display: none;
							}
						`}
					>
						<Flex
							direction="row"
							css={css`
								gap: 30px;
							`}
							alignItems="center"
						>
							{HeaderContent.map((item: HeaderContentType, index: number) => {
								return (
									<FlexItem key={index}>
										<Link to={item.url}>
											<Typography color={theme.colors.primary.secondaryBrand}>
												{item.label}
											</Typography>
										</Link>
									</FlexItem>
								);
							})}
						</Flex>
					</FlexItem>
					<FlexItem
						css={css`
							@media (min-width: calc( ${theme.breakpoints.sm} + 1px )) {
								display: none;
							}
						`}
					>
						<Flex
							direction="row"
							justifyContent="space-between"
							alignItems="center"
							css={css`
								@media (max-width: ${theme.breakpoints.lg}) {
									justify-content: flex-start;
									gap: 30px;
									width: calc(100% - 150px);
								}
							`}
						>
							<FlexItem
								css={css`
									@media (max-width: ${theme.breakpoints.lg}) {
										max-width: 50%;
									}
									@media (min-width: calc( ${theme.breakpoints.sm} + 1px )) {
										display: none;
									}
								`}
							>
								<Link to="/">
									<img
										src={"/images/the-next-shift.png"}
										css={css`
											display: block;
											width: 240px;
											height: auto;
											max-width: 100%;
										`}
									/>
								</Link>
							</FlexItem>
							<FlexItem
								css={css`
									@media (max-width: ${theme.breakpoints.lg}) {
										max-width: 50%;
									}
									@media (min-width: calc( ${theme.breakpoints.sm} + 1px )) {
										display: none;
									}
								`}
							>
								<img
									src={"/images/powered-by-ryerson.png"}
									css={css`
										width: 200px;
										height: auto;
										max-width: 100%;
									`}
								/>
								<Media greaterThanOrEqual="sm">
									<Link
										gatsby={false}
										target="_blank"
										to="https://mktg.ryerson.com/l/513681/2023-08-15/gfrm22"
									>
										<Button
											size="xs"
											label="Contact an Expert"
											shape="rounded"
											fullwidth={true}
											onClick={() => {}}
										/>
									</Link>
								</Media>
							</FlexItem>
						</Flex>
						<Media lessThan="sm">
							<div
								css={css`
									max-width: calc(100% - 150px);
									padding-top: 10px;
								`}
							>
								<Link
									gatsby={false}
									target="_blank"
									to="https://mktg.ryerson.com/l/513681/2023-08-15/gfrm22"
								>
									<Button
										size="xs"
										label="Contact an Expert"
										shape="rounded"
										fullwidth={true}
										onClick={() => {}}
									/>
								</Link>
							</div>
						</Media>
					</FlexItem>
					<FlexItem
						css={css`
							@media (max-width: ${theme.breakpoints.sm}) {
								display: none;
							}
						`}
					>
						<img
							src={"/images/powered-by-ryerson.png"}
							css={css`
								width: 200px;
								height: auto;
								max-width: 100%;
							`}
						/>
						<Link
							gatsby={false}
							target="_blank"
							to="https://mktg.ryerson.com/l/513681/2023-08-15/gfrm22"
						>
							<Button
								size="xs"
								label="Contact an Expert"
								shape="rounded"
								fullwidth={true}
								onClick={() => {}}
							/>
						</Link>
					</FlexItem>
					<FlexItem
						css={css`
							flex-grow: 1;
							@media (min-width: ${theme.breakpoints.lg}) {
								display: none;
							}
						`}
					>
						<Icon
							icon="bars"
							css={css`
								display: block;
								margin-left: auto;
							`}
							size="sm"
							color={theme.colors.primary.secondaryBrand}
							onClick={() => {
								setMenuShowing(!menuShowing);
							}}
						/>
					</FlexItem>
				</Flex>
			</Container>
			<CookieBanner />
		</>
	);
};

export default NextHeader;
